import React from 'react';
import { I18nextProvider } from 'react-i18next';
import i18n from './src/i18n';

export const wrapRootElement = ({ element }) => {
  // Asegurarse de que el código solo se ejecute en el navegador
  if (typeof window !== 'undefined') {
    // Código que depende de window puede ir aquí
    console.log("El código se está ejecutando en el cliente (navegador).");
  }

  return (
    <I18nextProvider i18n={i18n}>
      {element}
    </I18nextProvider>
  );
};
